import { graphql, useStaticQuery } from "gatsby";

const useHomePageData = () => {
  const { prismicHomepage } = useStaticQuery(
    graphql`
      query {
        prismicHomepage {
          data {
            title {
              text
            }
            description {
              text
            }
            cta {
              text
            }
            image {
              url
            }
            services {
              service_title {
                text
              }
              service_description {
                text
              }
              service_bg_image {
                url
              }
              service_image_mobile {
                url
              }
              service_link {
                type
                link_type
              }
            }
          }
        }
      }
    `
  );
  return prismicHomepage.data;
};

const useHomePageMessages = () => {
  const homePageData = useHomePageData();
  return {
    title: homePageData.title.text,
    description: homePageData.description.text,
    cta: homePageData.cta.text,
    imageUrl: homePageData.image.url,
    services: homePageData.services.map((service: any) => ({
      title: service.service_title.text,
      description: service.service_description.text,
      imageUrl: service.service_bg_image.url,
      mobileImageUrl: service.service_image_mobile.url,
      link: service.service_link
    }))
  };
};

export { useHomePageMessages };
