import React, { ButtonHTMLAttributes, FC } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  height: 48px;
  padding: 0 24px;

  display: flex;
  align-items: center;
  text-align: center;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
  background: #2c6dec;
  border-radius: 24px;
  border: none;
  cursor: pointer;
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: FC<Props> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export { Button };
