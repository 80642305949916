import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/layout";
import { HomePage } from "../modules/homepage/homepage";
import "../styles/1.reset.scss";
import "../styles/2.main.scss";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    offset: 96
  });
}

export default () => {
  return (
    <div>
      <Helmet>
        <title>App:storia</title>
      </Helmet>
      <Layout>
        <HomePage />
      </Layout>
    </div>
  );
};
