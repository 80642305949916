const linkResolver = (doc: any) => {
  switch (doc.type) {
    case "repair":
      return "/repair";

    case "tradeinpage":
      return `/trade-in`;

    default:
      return "/";
  }
};

export { linkResolver };
