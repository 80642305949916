import { Link } from "gatsby";
import { Link as PrismicLink } from "prismic-reactjs";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { Arrow, NextIcon } from "../../components/icons";
import { Modal } from "../../components/modal";
import { useHomePageMessages } from "../../hooks/useHomePageData";
import { linkResolver } from "../../utils/linkResolver";
import { RequestCallModal } from "./RequestCallModal";

const StylesHomePage = styled.div`
  display: flex;
  font-family: "Ubuntu";

  @media (max-width: 996px) {
    flex-direction: column;
  }

  .home-page__block {
    padding: 96px 20px 96px 132px;
    height: 804px;
    width: 100%;
    position: relative;
    @media (max-width: 996px) {
      padding: 40px;
      height: 664px;
    }

    @media (max-width: 720px) {
      padding: 32px;
      height: 512px;
      background-position: 75% bottom;
    }
  }

  .home-page__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .home-page__content {
    position: relative;
  }

  .home-page__title {
    max-width: 529px;
    font-size: 64px;
    line-height: 64px;
    font-weight: bold;

    @media (max-width: 996px) {
      font-size: 56px;
      line-height: 56px;
    }

    @media (max-width: 720px) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  .home-page__description {
    max-width: 390px;
    margin-top: 18px;
    font-size: 20px;
    line-height: 32px;

    color: #5b5b5b;
  }

  .home-page__cta {
    margin-top: 24px;
  }

  .home-page__services {
    display: flex;

    @media (min-width: 997px) {
      flex-direction: column;
    }

    @media (max-width: 720px) {
      flex-direction: column;
    }
  }

  .home-page__service {
    position: relative;
    overflow: hidden;
    width: 440px;
    height: 402px;
    padding: 56px;

    @media (max-width: 996px) {
      width: 100%;
      height: 312px;
    }

    @media (max-width: 768px) {
      padding: 40px;
    }

    @media (max-width: 720px) {
      margin-top: 8px;
      padding: 32px;
      width: 100%;
      height: 112px;

      background-color: #f2f2f2;
    }
  }

  .home-page__service:hover {
    .home-page__service-background {
      transform: scale(1.2);
    }
  }

  .home-page__service-content {
    position: relative;
  }

  .home-page__service-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform 0.5s;
  }

  .home-page__service-background--mobile {
    display: none;
  }

  .home-page__service-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: #000000;
  }

  .home-page__service-description {
    max-width: 448px;
    font-size: 16px;
    line-height: 24px;

    color: #5b5b5b;

    @media (max-width: 720px) {
      display: none;
    }
  }

  .home-page__service-icon {
    width: 32px;
    height: 32px;
    margin-top: 16px;

    @media (max-width: 720px) {
      display: none;
    }
  }

  .home-page__service-read-more {
    margin-top: 4px;

    color: #0057ff;

    @media (min-width: 721px) {
      display: none;
    }
  }

  .home-page__service-read-more-icon {
    margin-left: 8px;
  }

  @media (max-width: 720px) {
    .home-page__service-background--mobile {
      display: block;
    }

    .home-page__service-background:not(.home-page__service-background--mobile) {
      display: none;
    }
  }
`;

interface Service {
  imageUrl: string;
  mobileImageUrl: string;
  description: string;
  title: string;
  link: any;
}

const HomePage = () => {
  const { title, description, cta, imageUrl, services } = useHomePageMessages();

  const [isOpenRequestCallModal, setIsOpenRequestCallModal] = useState(false);
  const handleOpenRequestCallModal = useCallback(
    () => setIsOpenRequestCallModal(true),
    []
  );
  const handleCloseRequestCallModal = useCallback(
    () => setIsOpenRequestCallModal(false),
    []
  );

  return (
    <StylesHomePage>
      <div className="home-page__block">
        <img className="lazyload home-page__background" src={imageUrl} />

        <div className="home-page__content">
          <h1 className="home-page__title">{title}</h1>
          <p className="home-page__description">{description}</p>
          <Button
            className="home-page__cta"
            onClick={handleOpenRequestCallModal}
          >
            {cta}
          </Button>
        </div>
      </div>
      <div className="home-page__services">
        {services.map(
          ({ imageUrl, mobileImageUrl, description, title, link }: Service) => (
            <Link
              to={PrismicLink.url(link, linkResolver)}
              key={title}
              className="home-page__service"
            >
              <img
                className="lazyload home-page__service-background"
                data-src={imageUrl}
              />
              <img
                className="lazyload home-page__service-background home-page__service-background--mobile"
                data-src={mobileImageUrl}
              />
              <div className="home-page__service-content">
                <h3 className="home-page__service-title">{title}</h3>
                <p className="home-page__service-description">{description}</p>
                <NextIcon className="home-page__service-icon" />
                <div className="home-page__service-read-more">
                  Рассчитать стоимость
                  <Arrow className="home-page__service-read-more-icon" />
                </div>
              </div>
            </Link>
          )
        )}
      </div>
      <Modal
        isOpen={isOpenRequestCallModal}
        onClose={handleCloseRequestCallModal}
      >
        <RequestCallModal />
      </Modal>
    </StylesHomePage>
  );
};

export { HomePage };
